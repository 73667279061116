import { Periods } from '../components/Payments/Resources/types';
import { getMonthRange, getQuarterRange, getYearToDateRange } from './dateFormat';
export const formatMethodsToList = (methodsList, translate) => {
    const _array = [];
    methodsList === null || methodsList === void 0 ? void 0 : methodsList.forEach(method => {
        if (!method.default) {
            _array.push({
                label: getPaymentMethodName(method),
                value: method.id,
            });
        }
        else {
            _array.unshift({
                label: translate('payments.subscription.Default', { last4: getPaymentMethodName(method) }),
                value: method.id,
            });
        }
    });
    return _array;
};
export const getPaymentMethodNameById = (methodId, methodsList) => {
    const _paymentMethod = methodsList.find(method => method.id === methodId);
    if (!_paymentMethod) {
        return '';
    }
    return getPaymentMethodName(_paymentMethod);
};
export const getPaymentMethodName = (paymentMethod) => {
    return `${paymentMethod.card.brand.toUpperCase()} •••• ${paymentMethod.card.last4}`;
};
export const isCardExpired = (paymentMethod) => {
    const _month = paymentMethod.card.exp_month.toString();
    const month = _month.padStart(2, '0'); // Add leading zero if needed
    const year = paymentMethod.card.exp_year;
    const expirationDate = new Date(`${year}-${month}-01T00:00:00`);
    expirationDate.setMonth(expirationDate.getMonth() + 1);
    return expirationDate < new Date();
};
export const getConfirmSubscriptionErrorMessage = (stripeError) => {
    if (stripeError.includes('This customer has no attached payment source or default payment method.')) {
        return 'This customer has no attached payment source or default payment method. Please try again or reload page.';
    }
    return stripeError;
};
export const formatResourcesFilter = (filterObject) => {
    const _filter = {
        category: filterObject.category,
    };
    if (!filterObject.date || filterObject.date.period === Periods.curr_month) {
        const { start: dateFrom, end: dateTo } = getMonthRange(new Date());
        _filter.date = {
            dateFrom,
            dateTo,
        };
    }
    else if (filterObject.date.period === Periods.prev_month) {
        const currentDate = new Date();
        const prevYearAdjustment = currentDate.getMonth() === 0 ? 1 : 0;
        const prevMonthIndex = (currentDate.getMonth() + 11) % 12;
        const prevMonth = new Date(currentDate.getFullYear() - prevYearAdjustment, prevMonthIndex, 1);
        const { start: dateFrom, end: dateTo } = getMonthRange(prevMonth);
        _filter.date = {
            dateFrom,
            dateTo,
        };
    }
    else if (filterObject.date.period === Periods.quarter) {
        const { start: dateFrom, end: dateTo } = getQuarterRange(filterObject.date.quarter);
        _filter.date = {
            dateFrom,
            dateTo,
        };
    }
    else if (filterObject.date.period === Periods.year_to_date) {
        const { start: dateFrom, end: dateTo } = getYearToDateRange(new Date());
        _filter.date = {
            dateFrom,
            dateTo,
        };
    }
    else if (filterObject.date.period === Periods.custom) {
        _filter.date = {
            dateFrom: filterObject.date.range[0],
            dateTo: filterObject.date.range[1],
        };
    }
    return _filter;
};
function validateAddress(address) {
    if (!address)
        return false;
    const { city, country, line1, postal_code } = address;
    if (!city || city.trim().length === 0 || city.length > 100)
        return false;
    if (!country || country.trim().length === 0 || country.length > 100)
        return false;
    if (!line1 || line1.trim().length === 0 || line1.length > 200)
        return false;
    if (postal_code === undefined || postal_code.trim().length === 0 || postal_code.length > 20)
        return false;
    return true;
}
export function validateBillingInfo(billingInfo) {
    if (!billingInfo)
        return false;
    const { address, phone, name } = billingInfo;
    if (!validateAddress(address))
        return false;
    if (phone && phone.length > 20)
        return false;
    if (!name || name.trim().length === 0 || name.length > 200)
        return false;
    return true;
}
