export var Periods;
(function (Periods) {
    Periods["curr_month"] = "curr_month";
    Periods["prev_month"] = "prev_month";
    Periods["quarter"] = "quarter";
    Periods["year_to_date"] = "year_to_date";
    Periods["custom"] = "custom";
})(Periods || (Periods = {}));
export var Quarters;
(function (Quarters) {
    Quarters["q1"] = "q1";
    Quarters["q2"] = "q2";
    Quarters["q3"] = "q3";
    Quarters["q4"] = "q4";
    Quarters["undefined"] = "";
})(Quarters || (Quarters = {}));
