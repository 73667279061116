export const getRootWindow = () => {
    let rootWindow;
    if (window.opener) {
        rootWindow = window.opener;
    }
    else if (window.self !== window.top) {
        rootWindow = window.parent;
    }
    return rootWindow;
};
