/* eslint-disable max-len */
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import ShortUniqueId from 'short-unique-id';
import { computed } from 'vue';
import { useAuthStore } from '@/stores/auth/auth';
import settingsService from '../api/settings';
import config from '../config';
import helpers from '../helpers/index';
const rawAuthProviders = config.EXTERNAL_PROVIDERS || [];
const defaultLoginProvider = config.DEFAULT_LOGIN_PROVIDER;
// TODO: refactor this mess
export const useAuth = () => {
    const authStore = useAuthStore();
    const { isSSO, reactiveToken: storeToken, accountId: storeAccountId, redirectPath: storeRedirectPath, role: storeRole, } = storeToRefs(authStore);
    const authProviders = computed(() => rawAuthProviders);
    const getAuthProviders = () => authProviders.value;
    const getProviderByName = (name) => _.find(authProviders.value, { name });
    const initiateExternalLogin = (name, redirectPath) => {
        const locationUrl = generateProviderRedirectUrl(name, redirectPath);
        window.location.href = locationUrl || '';
    };
    const generateProviderRedirectUrl = (name, redirectPath, additionalParams = {}, relay_state = {}) => {
        const provider = _.find(authProviders.value, { name });
        if (!provider) {
            return;
        }
        const queryString = additionalParams;
        if (redirectPath) {
            // eslint-disable-next-line
            _.replace(redirectPath, new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
                if ($3) {
                    queryString[$1] = $3;
                }
                return '';
            });
        }
        const { url, clientId, redirectUri, responseType, scope } = provider;
        let fullRedirectUri = `${window.location.origin}/${redirectUri}`;
        if (!_.isEmpty(queryString) && provider.type !== 'OAuth') {
            fullRedirectUri = helpers.addQueryParams(fullRedirectUri, `state=${encodeURIComponent(JSON.stringify(queryString))}`);
        }
        fullRedirectUri = encodeURIComponent(fullRedirectUri);
        const uid = new ShortUniqueId();
        const locationUrl = `${url}?` +
            `${clientId ? `&client_id=${clientId}` : ''}` +
            `${responseType ? `&response_type=${responseType}` : ''}` +
            `${scope ? `&scope=${scope}` : ''}` +
            `&redirect_uri=${fullRedirectUri}` +
            `&nonce=${uid.randomUUID(5)}` +
            `&state=${_.isEmpty(relay_state) ? encodeURIComponent('{}') : encodeURIComponent(JSON.stringify(relay_state))}` +
            `${!_.isEmpty(relay_state && provider.type !== 'OAuth') ? '&' + helpers.serializeUrlParameters(relay_state) : ''}`;
        return locationUrl;
    };
    const authorizeRedirect = async (source = 'user-action') => {
        let settingRedirectUrl = await settingsService.getMergedSettings({ key: 'loginRedirectUrl' }).catch(error => {
            var _a, _b, _c;
            if ((_c = (_b = (_a = error.cause) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message.includes('suspended')) {
                window.location.href = `${config.AUTH_UI_URL}/account/payments`;
                return 'suspended';
            }
        });
        if (settingRedirectUrl === 'suspended')
            return;
        const defaultRedirectUrl = storeRole.value === 'GUEST' ? `${config.SHARED_URL}` : `${config.REPORTING_URL}/dashboard`;
        settingRedirectUrl = settingRedirectUrl ? settingRedirectUrl : defaultRedirectUrl;
        let redirectPath = storeRedirectPath.value ? storeRedirectPath.value : settingRedirectUrl;
        // TODO: Fix types, atm storeToken can be null;
        if (config.ADD_AUTH_TOKEN) {
            redirectPath = helpers.addQueryParams(redirectPath, `token=${encodeURIComponent(`${storeToken.value}`)}`);
        }
        if (isSSO.value) {
            let rootWindow;
            if (window.opener) {
                rootWindow = window.opener;
            }
            else if (window.self !== window.top) { // Check if current window is an iframe
                rootWindow = window.parent;
            }
            if (rootWindow) {
                rootWindow.postMessage({
                    type: 'LOGIN_SUCCESS',
                    token: storeToken.value,
                    accountId: storeAccountId.value,
                    source,
                }, '*');
            }
        }
        else {
            return window.location = redirectPath;
        }
    };
    const redirectToDefaultProviderIfExists = (redirectPath) => {
        initiateExternalLogin(defaultLoginProvider, redirectPath);
    };
    return {
        authProviders,
        getAuthProviders,
        getProviderByName,
        initiateExternalLogin,
        generateProviderRedirectUrl,
        authorizeRedirect,
        redirectToDefaultProviderIfExists,
    };
};
