export var IframeRedirectActions;
(function (IframeRedirectActions) {
    IframeRedirectActions["PaymentsRedirect"] = "PaymentsRedirect";
})(IframeRedirectActions || (IframeRedirectActions = {}));
export var IframeStatusMessages;
(function (IframeStatusMessages) {
    IframeStatusMessages["Success"] = "SUCCESS";
    IframeStatusMessages["InvalidAction"] = "INVALID_ACTION";
})(IframeStatusMessages || (IframeStatusMessages = {}));
export const AUTH_UI_MESSAGE = 'AUTH_UI_MESSAGE';
export const AUTH_UI_RESPONSE = 'AUTH_UI_RESPONSE';
