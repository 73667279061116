import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "px-md absolute left-0 right-0 top-sm"
};
const _hoisted_2 = {
    key: 1,
    class: "layout-column items-center"
};
const _hoisted_3 = { class: "font-display-small" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OrButton = _resolveComponent("OrButton");
    const _component_OrNotification = _resolveComponent("OrNotification");
    const _component_OrLoader = _resolveComponent("OrLoader");
    const _component_LoginContainer = _resolveComponent("LoginContainer");
    return (_openBlock(), _createBlock(_component_LoginContainer, {
        class: "page",
        background: true
    }, {
        default: _withCtx(() => [
            (_ctx.error || _ctx.authError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_OrNotification, {
                        class: "sm:max-w-[768px] mx-auto",
                        variant: "error",
                        "disable-close": ""
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('auth.external_provider.error', { errorMessage: _ctx.error ? _ctx.error : _ctx.authError })) + " ", 1),
                            _createVNode(_component_OrButton, {
                                variant: "link",
                                onClick: _ctx.logout
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('multi_user.set_password.Sign in')), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"])
                        ]),
                        _: 1
                    })
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_OrLoader, { class: "mb-sm" }),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('auth.external_provider.loggingIn')), 1)
                ]))
        ]),
        _: 1
    }));
}
