import { getCurrentInstance } from 'vue';
export default function useI18n() {
    const instance = getCurrentInstance();
    return {
        translate(str, values) {
            var _a;
            const i18n = (this === null || this === void 0 ? void 0 : this.$i18n) || ((_a = (instance === null || instance === void 0 ? void 0 : instance.root).ctx) === null || _a === void 0 ? void 0 : _a.$i18n);
            return i18n.t(str, values);
        },
    };
}
