import config from '../../env.json';
export const SSO_TEST_FEATURE = 'externalproviders';
let localFeature = '';
export const isExactFeature = (featureName, _config = config) => {
    // curch to save feature for locals.
    if (isLocal()) {
        localFeature || (localFeature = _config.FEATURE);
        return localFeature === featureName;
    }
    return _config.FEATURE === featureName;
};
export const isLocal = (_config = config) => {
    return _config.LOCAL;
};
