import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "dark:bg-background-dark flex grow justify-center items-center w-screen" };
const _hoisted_2 = { class: "flex w-full content-center items-center z-10 md:mx-3xl justify-center h-mobile-fix min-h-[100dvh]" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showBackground)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "hidden md:block absolute top-0 left-0 right-0 bottom-0 h-screen",
                style: _normalizeStyle(`background: url(${require('../assets/login-logo-bottom.png')}) right bottom no-repeat, url(${require('../assets/login-logo-top.png')}) left top no-repeat;`)
            }, null, 4))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default")
        ])
    ]));
}
