import { AUTH_UI_MESSAGE, AUTH_UI_RESPONSE, IframeRedirectActions, IframeStatusMessages } from './constants';
export const waitForIframeEvent = () => {
    return new Promise((resolve) => {
        const handler = (event) => {
            if (event.data.type === AUTH_UI_MESSAGE) {
                event.data.once && window.removeEventListener('message', handler);
                resolve(event.data);
            }
        };
        window.addEventListener('message', handler);
    });
};
export const validateIframeActions = (action) => {
    return typeof action === 'string' && action in IframeRedirectActions;
};
export const sendIframeResponseEvent = (status) => {
    if (status === IframeStatusMessages.InvalidAction) {
        window.parent.postMessage({
            type: AUTH_UI_RESPONSE,
            status: IframeStatusMessages.InvalidAction,
        }, '*');
        throw new Error(IframeStatusMessages.InvalidAction);
    }
    window.parent.postMessage({
        type: AUTH_UI_RESPONSE,
        status: IframeStatusMessages.Success,
    }, '*');
};
export const processIframeEvents = (action) => {
    switch (action) {
        case IframeRedirectActions.PaymentsRedirect:
            sendIframeResponseEvent(IframeStatusMessages.Success);
            return '/account/payments';
        default:
            sendIframeResponseEvent(IframeStatusMessages.InvalidAction);
    }
};
