import { OrButtonV3 as OrButton, OrInputV3 as OrInput, OrNotificationV3 as OrNotification, useResponsive, } from '@onereach/ui-components';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import { defineComponent, ref, computed, onBeforeUnmount } from 'vue';
import passwordApi from '../api/password';
import OrAnimationLogo from '../components/OrAnimationLogo/OrAnimationLogo.vue';
import OrLogo from '../components/OrLogo';
import PasswordStatus from '../components/PasswordStatus.vue';
import config from '../config';
import LoginContainer from '../layouts/LoginContainer.vue';
import { checkRwcInterval } from '../utils/constans';
import { passwordPolicies } from '../utils/PasswordPolicies';
export default defineComponent({
    components: {
        LoginContainer,
        OrButton,
        OrInput,
        PasswordStatus,
        OrNotification,
        OrAnimationLogo,
        OrLogo,
    },
    setup() {
        const password = ref('');
        const loading = ref(false);
        const passwordReseted = ref(false);
        const apiResponse = ref(null);
        const { isMobile, isDesktop } = useResponsive();
        const closeNotificiation = () => {
            apiResponse.value = null;
        };
        const rules = computed(() => ({
            password: {
                ...passwordPolicies,
                required,
            },
        }));
        const v$ = useVuelidate(rules, { password });
        const passwordInvalid = computed(() => {
            return v$.value.password.$error;
        });
        const passwordInput = ref(null);
        onBeforeUnmount(() => {
            if (config.HELPER_BOT_ENABLED) {
                // Show chat on pages except login
                const chat = document.querySelector('.or-rwc');
                if (chat) {
                    chat.style.display = 'block';
                }
            }
        });
        return {
            v$,
            password,
            loading,
            passwordPolicies,
            passwordReseted,
            apiResponse,
            closeNotificiation,
            passwordInvalid,
            passwordInput,
            isMobile,
            isDesktop,
        };
    },
    watch: {
        authorized(value) {
            if (value) {
                this.$router.push('/');
            }
        },
    },
    created() {
        const resetKey = _.get(this.$route, 'query.key');
        const resetEmail = _.get(this.$route, 'query.email');
        if (!resetKey || !resetEmail) {
            this.$router.push('/login');
        }
        if (config.HELPER_BOT_ENABLED) {
            // Hide chat on login page.
            const interval = setInterval(() => {
                const chat = document.querySelector('.or-rwc');
                if (chat) {
                    chat.style.display = 'none';
                    clearInterval(interval);
                }
            }, checkRwcInterval);
        }
    },
    methods: {
        async submit() {
            var _a, _b, _c, _d;
            this.v$.password.$touch();
            if (this.passwordInvalid) {
                (_a = this.passwordInput) === null || _a === void 0 ? void 0 : _a.focus();
                return;
            }
            const resetKey = _.get(this.$route, 'query.key');
            const resetEmail = _.get(this.$route, 'query.email');
            const { type: userType } = this.$route.query;
            this.loading = true;
            try {
                await passwordApi.endResetPassword({
                    email: resetEmail,
                    key: resetKey,
                    password: this.password,
                    type: userType,
                });
                this.apiResponse = {
                    type: 'success',
                    message: this.$t('login.password_restore.yourPasswordHas'),
                    linkText: this.$t('login.password_restore.Sign in'),
                };
            }
            catch (e) {
                this.apiResponse = {
                    type: 'error',
                    message: ((_d = (_c = (_b = e.cause) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) || 'Something went wrong - please try again later',
                };
            }
            this.loading = false;
        },
        login() {
            this.$router.push('/login');
        },
    },
});
