import { defineComponent } from 'vue';
import { useAuth } from '@/composables/useAuth';
import { useAuthStore } from '@/stores/auth/auth';
export default defineComponent({
    setup() {
        const { getProviderByName } = useAuth();
        const authStore = useAuthStore();
        return {
            getProviderByName,
            authStore,
            authorized: authStore.authorized,
            identityProvider: authStore.identityProvider,
            error: authStore.error,
            token: authStore.reactiveToken,
        };
    },
    async created() {
        try {
            await this.authStore.init({
                initialPath: '/',
            });
            await this.authStore.signOut(this.token);
            if (this.identityProvider) {
                const provider = this.getProviderByName(this.identityProvider.name);
                if (provider && provider.logoutUrl) {
                    window.location.href = provider.logoutUrl;
                    return;
                }
            }
            this.$router.replace({ name: 'login' });
        }
        catch (error) {
            await this.authStore.signOut(this.token);
            this.$router.replace({ name: 'login' });
        }
    },
});
