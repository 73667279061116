import { ref } from 'vue';
import useI18n from '@/composables/useI18n';
import TranslatableError from '@/errors/TranslatableError';
import { useAuthStore } from '@/stores/auth/auth';
import { getFormatedError } from '../utils/formatAuthError';
/**
 * Hook for handling Two factor recovery form functionality.
 *
 * @param {Object} accountData - Account data object.
 * @param {Function} getCaptchaToken - Function to get captcha token.
 * @param {Function} errorProcessor - Function to process auth error message.
 * @param {Ref<string>} username - Username for submiting.
 * @param {Ref<string>} password - Password for submiting.
 * @param {Ref<boolean>} keepLoggedIn - Keep me logged in flag for submiting.
 * @returns Two factor recovery form state and methods.
 */
export const useMfaRecoveryModal = (accountData, getCaptchaToken, errorProcessor, username, password, keepLoggedIn) => {
    const authStore = useAuthStore();
    const { translate } = useI18n();
    const recoveryCode = ref('');
    const isLoadingMfaRecovery = ref(false);
    const recoveryError = ref('');
    const isOpenRecovery2fa = ref(false);
    const toogleOpenRecovery2fa = (bool) => {
        isOpenRecovery2fa.value = bool;
        if (!bool) {
            recoveryCode.value = '';
            recoveryError.value = '';
        }
    };
    const submitMfaRecovery = async (captchaV2CheckboxToken) => {
        if (authStore.sso_needs_two_factor) {
            isLoadingMfaRecovery.value = true;
            await authStore.ssoLoginMFA(recoveryCode.value).catch(error => {
                if (typeof error === 'string') {
                    const errorObj = getFormatedError(error, translate);
                    recoveryError.value = errorObj.message;
                    if ('action' in errorObj) {
                        errorProcessor(errorObj.action);
                    }
                }
                else {
                    throw new TranslatableError('errors.commonText', error);
                }
            });
            isLoadingMfaRecovery.value = false;
            return;
        }
        recoveryError.value = '';
        isLoadingMfaRecovery.value = true;
        const captchaToken = await getCaptchaToken(captchaV2CheckboxToken);
        await authStore.signIn({
            credentials: {
                password: password.value,
                username: username.value,
            },
            keepLoggedIn: keepLoggedIn.value,
            verificationCode: recoveryCode.value,
            captchaToken,
            isCaptchaV2Checkbox: typeof captchaV2CheckboxToken === 'string',
            accountData: accountData,
        }).catch((error) => {
            isLoadingMfaRecovery.value = false;
            if (error instanceof Error || typeof Error === 'string') {
                const errorObj = getFormatedError(error, translate);
                recoveryError.value = errorObj.message;
                if ('action' in errorObj) {
                    errorProcessor(errorObj.action);
                }
            }
            else {
                throw error;
            }
        });
        return;
    };
    return {
        recoveryCode,
        isLoadingMfaRecovery,
        recoveryError,
        isOpenRecovery2fa,
        toogleOpenRecovery2fa,
        submitMfaRecovery,
    };
};
