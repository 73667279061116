import RethrownError from './RethrownError';
class TranslatableError extends RethrownError {
    constructor(translationKey, innerError) {
        const e = innerError instanceof Error ? innerError : new Error('' + innerError);
        super(`An error occurred: ${translationKey}.`, e);
        this.translationKey = translationKey;
        this.innerError = innerError;
        Object.setPrototypeOf(this, TranslatableError.prototype);
    }
}
export default TranslatableError;
