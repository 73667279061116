// Modified from: https://stackoverflow.com/questions/42754270/
class RethrownError extends Error {
    constructor(message, originalError, skipOwnStack = false) {
        var _a;
        super(message);
        this.originalError = originalError;
        this.name = this.constructor.name;
        const messageLines = (this.message.match(/\n/g) || []).length + 1;
        this.stack = skipOwnStack ?
            originalError.stack :
            (((_a = this.stack) !== null && _a !== void 0 ? _a : '').split('\n').slice(0, messageLines + 1).join('\n') + '\n' + originalError.stack);
        Object.setPrototypeOf(this, RethrownError.prototype);
    }
}
export default RethrownError;
