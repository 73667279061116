import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useI18n from '@/composables/useI18n';
import TranslatableError from '@/errors/TranslatableError';
import { useAuthStore } from '@/stores/auth/auth';
import { getFormatedError } from '../utils/formatAuthError';
/**
 * Hook for handling Two factor authentication form functionality.
 *
 * @param {Object} accountData - Account data object.
 * @param {Function} getCaptchaToken - Function to get captcha token.
 * @param {Function} errorProcessor - Function to process auth error message.
 * @param {Ref<string>} username - Username for submiting.
 * @param {Ref<string>} password - Password for submiting.
 * @param {Ref<boolean>} keepLoggedIn - Keep me logged in flag for submiting.
 * @returns Two factor authentication form state and methods.
 */
export const useMfaForm = (accountData, getCaptchaToken, errorProcessor, username, password, keepLoggedIn) => {
    const authStore = useAuthStore();
    const router = useRouter();
    const { translate } = useI18n();
    const verificationCode = ref('');
    const isLoadingMfa = ref(false);
    const mfaError = ref('');
    const logout = async () => {
        authStore.cancelTwoFactorCheck();
        router.push('/logout');
    };
    const pasteMfaFromBuffer = async () => {
        try {
            if (!navigator.clipboard) {
                throw new Error('Clipboard API not available');
            }
            const text = await navigator.clipboard.readText();
            verificationCode.value = text;
        }
        catch (e) {
            console.error('Failed to read clipboard contents: ', e);
            if (e instanceof Error) {
                mfaError.value = e.message;
            }
        }
    };
    const submitMfa = async (captchaV2CheckboxToken) => {
        if (authStore.sso_needs_two_factor) {
            isLoadingMfa.value = true;
            await authStore.ssoLoginMFA(verificationCode.value).catch(error => {
                if (typeof error === 'string') {
                    const errorObj = getFormatedError(error, translate);
                    mfaError.value = errorObj.message;
                    if ('action' in errorObj) {
                        errorProcessor(errorObj.action);
                    }
                }
                else {
                    throw new TranslatableError('errors.commonText', error);
                }
            });
            isLoadingMfa.value = false;
            return;
        }
        mfaError.value = '';
        isLoadingMfa.value = true;
        const captchaToken = await getCaptchaToken(captchaV2CheckboxToken);
        await authStore.signIn({
            credentials: {
                password: password.value,
                username: username.value,
            },
            keepLoggedIn: keepLoggedIn.value,
            verificationCode: verificationCode.value,
            captchaToken,
            isCaptchaV2Checkbox: typeof captchaV2CheckboxToken === 'string',
            accountData: accountData,
        }).catch((error) => {
            isLoadingMfa.value = false;
            if (error instanceof Error || typeof Error === 'string') {
                const errorObj = getFormatedError(error, translate);
                mfaError.value = errorObj.message;
                if ('action' in errorObj) {
                    errorProcessor(errorObj.action);
                }
            }
            else {
                throw error;
            }
        });
        return;
    };
    return {
        verificationCode,
        isLoadingMfa,
        mfaError,
        logout,
        pasteMfaFromBuffer,
        submitMfa,
    };
};
