import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { computed, defineComponent, toRef } from 'vue';
export default defineComponent({
    props: {
        password: {
            type: String,
            required: true,
        },
        passwordPolicies: {
            type: Object,
            required: true,
        },
        dirty: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const password = toRef(props, 'password');
        const rules = computed(() => ({
            password: {
                ...props.passwordPolicies,
                required,
            },
        }));
        const v$ = useVuelidate(rules, { password: password });
        const passwordPoliciesExtended = computed(() => {
            return Object.keys(props.passwordPolicies).map(name => {
                let classes = [];
                if (v$.value.password.$model) {
                    const policyInvalid = v$.value.password[name].$invalid;
                    classes = policyInvalid ? ['text-error', 'dark:text-error-dark'] : ['text-success', 'dark:text-success-dark'];
                }
                else if (props.dirty) {
                    classes = ['text-error', 'dark:text-error-dark'];
                }
                return {
                    name,
                    classes,
                };
            });
        });
        return {
            v$,
            passwordPoliciesExtended,
        };
    },
});
