import '@onereach/styles/main-v3.css';
import { createApp } from 'vue';
import createPinia from '@/stores/main';
import App from './app.vue';
import i18n from './i18n/i18n';
import router from './router/router';
import SentryApi from './sentry';
const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(i18n);
SentryApi.init(app, router);
app.mount('#app');
