import { defineStore } from 'pinia';
import { ref } from 'vue';
import authService from '../../api/auth';
import settingsService from '../../api/settings';
import config from '../../config';
import router from '../../router/router';
import { getRootWindow } from '../../utils/sso';
import { usePaymentsStore } from '../payments';
const cookieMonitorLogoutCallback = (callback) => () => {
    callback(true);
};
export const useAuthStore = defineStore('auth', () => {
    // state
    const token = () => {
        return authService.getUserToken();
    };
    const reactiveToken = ref(null);
    const tokenExpireTime = ref(null);
    const accountId = ref(null);
    const username = ref(null);
    const userId = ref(null);
    const error = ref(null);
    const authorized = ref(null);
    const multiUserId = ref(null);
    const role = ref(null);
    const initialPath = ref(null);
    const redirectPath = ref(null);
    const allowGuest = ref(false);
    const twoFactorCheck = ref(false);
    const twoFactorEnabled = ref(false);
    const identityProvider = ref(null);
    const multiUserIsLoggingUser = ref(false);
    const multiUser = ref(null);
    const availableUsers = ref([]);
    const cookiesExpired = ref(false);
    const cookiesAnotherUser = ref(false);
    const cookiesNoRights = ref(false);
    const sso_needs_two_factor = ref(false);
    const isSSO = ref(false);
    const sso_key = ref(null);
    const permissions = ref(undefined);
    const isReadonly = ref(false);
    const isAdmin = ref(true);
    const isPayable = ref(false);
    const isSuspended = ref(false);
    const _authCookiesExpired = (value) => {
        cookiesExpired.value = value;
        cookiesAnotherUser.value = false;
        cookiesNoRights.value = false;
    };
    const _authAnotherUser = () => {
        cookiesExpired.value = false;
        cookiesAnotherUser.value = true;
        cookiesNoRights.value = false;
    };
    const _authNoRights = () => {
        cookiesExpired.value = false;
        cookiesAnotherUser.value = false;
        cookiesNoRights.value = true;
    };
    const _authInit = (initState) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        accountId.value = (_a = initState.accountId) !== null && _a !== void 0 ? _a : null;
        multiUserId.value = (_b = initState.multiUserId) !== null && _b !== void 0 ? _b : null;
        username.value = (_c = initState.username) !== null && _c !== void 0 ? _c : null;
        userId.value = (_d = initState.userId) !== null && _d !== void 0 ? _d : null;
        role.value = (_e = initState.role) !== null && _e !== void 0 ? _e : null;
        authorized.value = (_f = initState.authorized) !== null && _f !== void 0 ? _f : null;
        error.value = (_g = initState.error) !== null && _g !== void 0 ? _g : null;
        initialPath.value = initState.initialPath;
        identityProvider.value = (_h = initState.identityProvider) !== null && _h !== void 0 ? _h : null;
        twoFactorEnabled.value = (_j = initState.twoFactorEnabled) !== null && _j !== void 0 ? _j : false;
        permissions.value = (_k = initState.permissions) !== null && _k !== void 0 ? _k : undefined;
        reactiveToken.value = (_l = initState.token) !== null && _l !== void 0 ? _l : null;
        isReadonly.value = (role.value === 'USER' && ((_o = (_m = permissions.value) === null || _m === void 0 ? void 0 : _m.scope) === null || _o === void 0 ? void 0 : _o.includes('READONLY'))) || false;
        isAdmin.value = role.value === 'ADMIN' || role.value === 'SUPER_ADMIN';
    };
    const _setIsPayable = (value) => {
        isPayable.value = value;
    };
    const _authSuccess = (authData) => {
        accountId.value = authData.accountId;
        username.value = authData.username;
        userId.value = authData.userId;
        role.value = authData.role;
        multiUserId.value = authData.multiUserId;
        identityProvider.value = authData.identityProvider;
        twoFactorEnabled.value = authData.twoFactorEnabled;
        reactiveToken.value = authData.token;
        authorized.value = true;
        twoFactorCheck.value = false;
        error.value = null;
        sso_key.value = null;
    };
    // TODO: Refactor and remove event-based errors, process errors in different way.
    const _authError = (_error) => {
        accountId.value = null;
        username.value = null;
        userId.value = null;
        role.value = null;
        identityProvider.value = null;
        authorized.value = false;
        error.value = _error;
        multiUserId.value = null;
    };
    const _signOut = () => {
        accountId.value = null;
        username.value = null;
        userId.value = null;
        role.value = null;
        authorized.value = false;
        error.value = null;
        identityProvider.value = null;
        twoFactorCheck.value = false;
        initialPath.value = null;
        multiUserId.value = null;
        reactiveToken.value = null;
        sso_needs_two_factor.value = false;
        multiUser.value = null;
        sso_key.value = null;
        isPayable.value = false;
        isSuspended.value = false;
    };
    const _twoFactorCheck = (value) => {
        twoFactorCheck.value = value;
    };
    const _setRedirectUrl = (value) => {
        redirectPath.value = value;
    };
    const _multiUserIsLogginUser = (value) => {
        multiUserIsLoggingUser.value = value;
    };
    const _authSetMultiUser = (value) => {
        multiUser.value = value;
    };
    const _multiUserToggleMFA = () => {
        if (multiUser.value) {
            multiUser.value.twoFactorCheck = !multiUser.value.twoFactorCheck;
        }
    };
    const _multiUserAvailableUsers = (users) => {
        availableUsers.value = users;
    };
    const _setIsSso = () => {
        isSSO.value = true;
    };
    const _ssoNeedsTwoFactor = ({ error: _error, KEY }) => {
        twoFactorCheck.value = true;
        error.value = _error;
        sso_needs_two_factor.value = true;
        if (KEY) {
            sso_key.value = KEY;
        }
    };
    const _authSuspended = ({ suspended }) => {
        isSuspended.value = suspended;
    };
    const _cookieMonitorLoginCallback = () => (newUser) => {
        var _a;
        if (!userId.value || ((_a = newUser === null || newUser === void 0 ? void 0 : newUser.userId) !== null && _a !== void 0 ? _a : null) === userId.value) {
            if (cookiesExpired.value) {
                _authCookiesExpired(false);
            }
            if (cookiesAnotherUser.value) {
                _authCookiesExpired(true);
            }
        }
        else {
            if (!cookiesAnotherUser.value) {
                _authAnotherUser();
            }
        }
    };
    const updateReactiveToken = ({ token, expire }) => {
        reactiveToken.value = token;
        tokenExpireTime.value = expire;
    };
    const init = async ({ initialPath, clearCookies = true }) => {
        authService.tokenUpdateCallback = (user) => {
            updateReactiveToken(user);
        };
        // if initial path starts with '/login' then replace it with '/'
        initialPath = initialPath.match(/^\/login/) ? '/' : initialPath;
        const callback = (user) => {
            authService.monitorCookie(cookieMonitorLogoutCallback(_authCookiesExpired), _cookieMonitorLoginCallback());
            _authInit({
                ...user,
                authorized: true,
                initialPath,
            });
            authService.validateMultiUserToken().catch(console.warn);
        };
        return authService.validateUser(true)
            .then(user => callback(user))
            .catch(async (error) => {
            var _a, _b, _c;
            if ((_c = (_b = (_a = error.cause) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message.includes('account is suspended')) {
                // TODO: change to straight up value;
                // TODO: change naming;
                _authSuspended({ suspended: true });
                // retry user valitaion with suspended route
                const userOrError = await authService.validateUser(true, false, true).catch(error => error);
                if (!(userOrError instanceof Error)) {
                    callback(userOrError);
                    return userOrError;
                }
                else
                    error = userOrError;
            }
            if (clearCookies)
                authService.signOut();
            authService.monitorCookie(() => { }, (loggedInUser) => {
                // TODO: refactor
                _authInit({
                    ...loggedInUser,
                    authorized: true,
                    initialPath,
                });
            });
            _authInit({
                authorized: false,
                initialPath,
            });
            if (error.errorType === 'NoRightsError') {
                _authNoRights();
            }
            throw error;
        });
    };
    const refreshSuspendedUserToken = async () => {
        await authService.refreshUserToken();
        return authService.validateUser(true).then(user => {
            _authSuspended({ suspended: false });
            _authInit({
                ...user,
                authorized: true,
            });
        })
            .catch(async (error) => {
            var _a, _b, _c;
            if ((_c = (_b = (_a = error.cause) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message.includes('account is suspended')) {
                _authSuspended({ suspended: true });
            }
            else {
                console.warn(error);
            }
        });
    };
    const signIn = async ({ credentials, keepLoggedIn, verificationCode, captchaToken, isCaptchaV2Checkbox, rememberTwoFactor, accountData, }) => {
        return authService.signIn({
            long: keepLoggedIn,
            allowGuestLogin: allowGuest.value,
            credentials,
            captchaToken,
            isCaptchaV2Checkbox,
            verificationCode,
            rememberTwoFactor,
        })
            .then(async (result) => {
            if (result.twoFactorCheck) {
                _twoFactorCheck(true);
            }
            else {
                if (result.tokenType === 'multi-user') {
                    await validateMultiUser();
                    return await authorizeMult({
                        keepLoggedIn,
                        accountData,
                    });
                }
                // Edgecase for single users;
                // TODO: Test how single users operate in depth;
                _authSuccess(result);
            }
        })
            .catch(error => {
            _authError(error);
            throw error;
        });
    };
    const authorizeMult = async ({ keepLoggedIn, accountData, source = 'user-action', }) => {
        var _a, _b;
        sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem('keepLoggedIn', `${keepLoggedIn}`);
        await loadUsers();
        const previousMultRoute = sessionStorage.getItem('redirect-mult') || '';
        if (/^\/or-auth/.test(previousMultRoute)) {
            sessionStorage.removeItem('redirect-mult');
            return router.push(previousMultRoute);
        }
        let accountUser, foundUserFromSSO;
        if (accountData && Object.keys(accountData).length > 0) {
            accountUser = availableUsers.value.find((user) => Object.keys(accountData).some(prop => user[prop] === accountData[prop]));
            foundUserFromSSO = !!accountUser;
        }
        if (availableUsers.value.length === 1) {
            accountUser = availableUsers.value[0];
        }
        if (accountUser) {
            // log user in; get token
            try {
                await multiUserLoginUser({
                    ...accountUser,
                    long: keepLoggedIn,
                });
            }
            catch (e) {
                return router.push('/multi-user/list-users');
            }
            // accessing account
            try {
                if (isSSO.value) {
                    let rootWindow;
                    if (window.opener) {
                        rootWindow = window.opener;
                    }
                    else if (window.self !== window.top) { // Check if current window is an iframe
                        rootWindow = window.parent;
                    }
                    if (rootWindow) {
                        return rootWindow.postMessage({
                            type: 'LOGIN_SUCCESS',
                            token: token(),
                            accountId: accountId.value,
                            source,
                        }, '*');
                    }
                }
                else {
                    let settingRedirectUrl = await settingsService.getMergedSettings({ key: 'loginRedirectUrl' }).catch(error => {
                        var _a, _b, _c;
                        if ((_c = (_b = (_a = error.cause) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message.includes('suspended')) {
                            return `${config.AUTH_UI_URL}/account/payments`;
                        }
                    });
                    const defaultRedirectUrl = `${config.REPORTING_URL}/dashboard`;
                    settingRedirectUrl = settingRedirectUrl ? settingRedirectUrl : defaultRedirectUrl;
                    const _redirectPath = redirectPath.value ? redirectPath.value : settingRedirectUrl;
                    return window.location = _redirectPath;
                }
            }
            catch (error) {
                _authError(error);
                throw error;
            }
        }
        else if (isSSO.value && !foundUserFromSSO) {
            const rootWindow = getRootWindow();
            if (rootWindow) {
                return rootWindow.postMessage({
                    type: 'ACCOUNT_NOT_FOUND',
                    availableUsers: JSON.parse(JSON.stringify(availableUsers.value)),
                    username: (_b = (_a = multiUser.value) === null || _a === void 0 ? void 0 : _a.username) !== null && _b !== void 0 ? _b : null,
                    source,
                }, '*');
            }
        }
        return router.push('/multi-user/list-users');
    };
    const recoverSession = async ({ credentials, keepLoggedIn, verificationCode, userToken, rememberTwoFactor, userId, accountId, captchaToken, }) => {
        try {
            let result = await authService.signIn({
                credentials,
                long: keepLoggedIn,
                verificationCode,
                userToken,
                rememberTwoFactor,
                captchaToken,
            });
            if (result.tokenType === 'multi-user' && !result.twoFactorCheck) {
                result = await authService.multiUserLoginUser({
                    accountId: accountId,
                    id: userId,
                    long: keepLoggedIn,
                });
            }
            if (result.twoFactorCheck) {
                _twoFactorCheck(true);
            }
            else {
                // Edgecase for single users;
                _authSuccess(result);
            }
            return result;
        }
        catch (error) {
            _authError(error);
        }
    };
    const cancelTwoFactorCheck = () => {
        _twoFactorCheck(false);
    };
    const signInWithMultToken = async ({ user, keepLoggedIn }) => {
        await authService.saveMultiUser(user, keepLoggedIn);
        return authorizeMult({ keepLoggedIn });
    };
    const signInWithToken = async ({ token, allowGuestLogin }) => {
        return await authService.signInWithToken({
            token,
            allowGuestLogin,
        })
            .then(result => {
            // Edgecase for single users;
            _authSuccess(result);
        })
            .catch(error => {
            console.error('ERROR', error);
            _authError(error);
        });
    };
    const loadUsers = async () => {
        const availableUsers = await authService.multiUserGetUsersList();
        _multiUserAvailableUsers(availableUsers);
    };
    const sucessAuth = (user) => {
        _authInit({
            ...user,
            authorized: true,
        });
    };
    const setRedirectUrl = (redirectPath) => {
        const currentHostname = window.location.hostname.split('.');
        const validUrl = new URL(redirectPath).hostname.split('.');
        const currentDomain = currentHostname.slice(-2).join('.');
        const validDomain = validUrl.slice(-2).join('.');
        if (currentDomain === validDomain) {
            // TODO: maybe remove method completelly.
            _setRedirectUrl(redirectPath);
        }
        else {
            window.location.href = '/login';
        }
    };
    const signOut = async (token) => {
        await authService.signOut(token);
        authService.stopCookieMonitor();
        _signOut();
    };
    const multiUserLoginUser = async (selected) => {
        _multiUserIsLogginUser(true);
        try {
            const user = await authService.multiUserLoginUser(selected);
            _authInit({
                ...user,
                authorized: true,
                initialPath: initialPath.value,
            });
        }
        catch (e) {
            console.warn(e);
            throw e;
        }
        finally {
            _multiUserIsLogginUser(false);
        }
    };
    const validateMultiUser = async () => {
        if (multiUser.value)
            return;
        const _multiUser = await authService.validateMultiUserToken();
        _authSetMultiUser(_multiUser);
    };
    const toggleMultiUserMFA = () => {
        _multiUserToggleMFA();
    };
    const setSSOTwoFactor = ({ error, KEY }) => {
        _ssoNeedsTwoFactor({
            error,
            KEY,
        });
    };
    const setIsSSO = () => {
        _setIsSso();
    };
    // TODO: Remove from here, to useLoginIFrame/or w.e.
    const ssoLoginMFA = async (verificationCode) => {
        const user = await fetch(`${config.GLOBAL_SSO_URL}/mfa`, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                GATEWAY_URL: config.GATEWAY_URL,
                PROVIDERS_ACCOUNT_ID: config.PROVIDERS_ACCOUNT_ID,
                MFACode: verificationCode,
                KEY: sso_key.value,
            }),
        }).then(r => r.json());
        window.postMessage(user.token ? {
            type: 'SSO_SUCCESS_LOGIN',
            user,
        } : {
            type: 'SSO_TWO_FACTOR_CHECK',
            error: user.error,
            KEY: user.KEY,
        }, '*');
        if ('error' in user) {
            throw user.error;
        }
    };
    const getAccountIsPayable = async () => {
        if (config.ENABLE_PAYMENTS) {
            const paymentsStore = usePaymentsStore();
            const _isPayable = await paymentsStore.getPayableStatus().then((response) => response.billable);
            _setIsPayable(_isPayable);
        }
    };
    return {
        // state
        token,
        reactiveToken,
        tokenExpireTime,
        accountId,
        username,
        userId,
        error,
        authorized,
        multiUserId,
        role,
        initialPath,
        redirectPath,
        allowGuest,
        twoFactorCheck,
        twoFactorEnabled,
        identityProvider,
        multiUserIsLoggingUser,
        multiUser,
        availableUsers,
        cookiesExpired,
        cookiesAnotherUser,
        cookiesNoRights,
        sso_needs_two_factor,
        isSSO,
        sso_key,
        permissions,
        isReadonly,
        isAdmin,
        isPayable,
        isSuspended,
        // mutations
        _authSuspended,
        // actions
        init,
        refreshSuspendedUserToken,
        signIn,
        authorizeMult,
        recoverSession,
        cancelTwoFactorCheck,
        signInWithMultToken,
        signInWithToken,
        loadUsers,
        sucessAuth,
        setRedirectUrl,
        signOut,
        multiUserLoginUser,
        validateMultiUser,
        toggleMultiUserMFA,
        setSSOTwoFactor,
        setIsSSO,
        ssoLoginMFA,
        getAccountIsPayable,
    };
});
