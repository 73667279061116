import _ from 'lodash';
import config from '../config';
const env = config.FLOW_BUILDER_URL;
const envReporting = config.REPORTING_URL;
const envCustomData = config.CRM_URL;
export default {
    getBotUrl(botId) {
        return botId ? `${env}/#/bots/${botId}` : env;
    },
    getCardTemplateUrl(cardTemplateId) {
        return cardTemplateId ? `${envReporting}/#/card-templates/list/${cardTemplateId}` : envReporting;
    },
    getCustomDataUrl() {
        return envCustomData;
    },
    getFlowUrl(flowId, botId) {
        return botId && flowId ? `${env}/#/flows/${botId}/${flowId}` : env;
    },
    getViewUrl(viewId) {
        return viewId ? `${envReporting}/#/views/${viewId}` : envReporting;
    },
    addQueryParams(url, params) {
        return `${url}${_.split(url, '?')[1] ? '&' : '?'}${params}`;
    },
    removeURLParameter(url, parameter) {
        const urlparts = _.split(url, '?');
        if (urlparts.length >= 2) {
            const prefix = `${encodeURIComponent(parameter)}=`;
            let pars = _.split(urlparts[1], /[&;]/g);
            //reverse pars as may be destructive
            _.reverse(pars);
            pars = _.filter(pars, param => !_.includes(param, prefix));
            const finalParams = pars.length > 0 ? `?${pars.join('&')}` : '';
            return `${urlparts[0]}${finalParams}`;
        }
        else {
            return url;
        }
    },
    serializeUrlParameters(obj) {
        const str = [];
        for (const p in obj)
            if (obj[p]) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        return str.join('&');
    },
};
