import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withKeys as _withKeys } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "px-md absolute left-0 right-0 top-sm"
};
const _hoisted_2 = { class: "layout-row grow px-md sm:px-0 sm:justify-center md:justify-between max-w-[1044px]" };
const _hoisted_3 = {
    key: 0,
    class: "flex h-[504px] w-[492px] justify-center items-center"
};
const _hoisted_4 = { class: "layout-column w-full sm:w-[343px] md:w-[352px] gap-xl" };
const _hoisted_5 = { class: "layout-column gap-md items-center md:items-stretch" };
const _hoisted_6 = { class: "typography-display-small" };
const _hoisted_7 = { class: "typography-body-2-regular" };
const _hoisted_8 = { class: "layout-column gap-md" };
const _hoisted_9 = { class: "strength" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    const _component_OrNotification = _resolveComponent("OrNotification");
    const _component_OrAnimationLogo = _resolveComponent("OrAnimationLogo");
    const _component_OrLogo = _resolveComponent("OrLogo");
    const _component_OrInput = _resolveComponent("OrInput");
    const _component_PasswordStatus = _resolveComponent("PasswordStatus");
    const _component_OrButton = _resolveComponent("OrButton");
    const _component_LoginContainer = _resolveComponent("LoginContainer");
    return (_openBlock(), _createBlock(_component_LoginContainer, { class: "reset-form" }, {
        default: _withCtx(() => {
            var _a, _b;
            return [
                (_ctx.apiResponse)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_OrNotification, {
                            class: "alert-response sm:max-w-[768px] mx-auto",
                            variant: _ctx.apiResponse.type,
                            onClose: _ctx.closeNotificiation
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.apiResponse.message) + " ", 1),
                                (_ctx.apiResponse.linkText)
                                    ? (_openBlock(), _createBlock(_component_router_link, {
                                        key: 0,
                                        class: "alert-link !text-primary-dark",
                                        to: "/logout"
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.apiResponse.linkText), 1)
                                        ]),
                                        _: 1
                                    }))
                                    : _createCommentVNode("", true)
                            ]),
                            _: 1
                        }, 8, ["variant", "onClose"])
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_2, [
                    (_ctx.isDesktop)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_OrAnimationLogo)
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_OrLogo, { class: "self-center md:hidden mb-md" }),
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('login.password_restore.resetPassword')), 1),
                            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('login.password_restore.setYourNewPassword')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_OrInput, {
                                ref: "passwordInput",
                                modelValue: _ctx.password,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.password) = $event)),
                                type: "password",
                                readonly: ((_a = _ctx.apiResponse) === null || _a === void 0 ? void 0 : _a.type) === 'success',
                                label: _ctx.$t('login.password_restore.password'),
                                placeholder: _ctx.$t('session_expire.Enter password'),
                                disabled: _ctx.loading,
                                error: _ctx.passwordInvalid,
                                onBlur: _cache[1] || (_cache[1] = ($event) => (_ctx.v$.password.$touch())),
                                onKeyup: _withKeys(_ctx.submit, ["enter"])
                            }, null, 8, ["modelValue", "readonly", "label", "placeholder", "disabled", "error", "onKeyup"]),
                            _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_PasswordStatus, {
                                    password: _ctx.password,
                                    "password-policies": _ctx.passwordPolicies,
                                    dirty: _ctx.passwordInvalid
                                }, null, 8, ["password", "password-policies", "dirty"])
                            ])
                        ]),
                        _createVNode(_component_OrButton, {
                            class: "sign-in w-full",
                            color: "primary",
                            disabled: ((_b = _ctx.apiResponse) === null || _b === void 0 ? void 0 : _b.type) === 'success',
                            loading: _ctx.loading,
                            onClick: _ctx.submit
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('login.password_restore.set')), 1)
                            ]),
                            _: 1
                        }, 8, ["disabled", "loading", "onClick"])
                    ])
                ])
            ];
        }),
        _: 1
    }));
}
