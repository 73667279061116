import { Quarters } from '../components/Payments/Resources/types';
export const formatToLongDate = (date) => {
    const _date = new Date(date);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    return _date.toLocaleDateString('en-US', options);
};
export const formatUnixTimestamp = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString();
};
export const getMonthRange = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const startOfMonth = new Date(year, month - 1, 1);
    const endOfMonth = new Date(year, month, 0);
    return {
        start: startOfMonth,
        end: endOfMonth,
    };
};
export const getQuarterRange = (quarter) => {
    const currentYear = new Date().getFullYear();
    let startDate, endDate;
    switch (quarter) {
        case Quarters.q1:
            startDate = new Date(currentYear, 0, 1); // January 1st
            endDate = new Date(currentYear, 2, 31); // March 31st
            break;
        case Quarters.q2:
            startDate = new Date(currentYear, 3, 1); // April 1st
            endDate = new Date(currentYear, 5, 30); // June 30th
            break;
        case Quarters.q3:
            startDate = new Date(currentYear, 6, 1); // July 1st
            endDate = new Date(currentYear, 8, 30); // September 30th
            break;
        case Quarters.q4:
            startDate = new Date(currentYear, 9, 1); // October 1st
            endDate = new Date(currentYear, 11, 31); // December 31st
            break;
        default:
            throw new Error('Invalid quarter. Quarter must be in the format "q1" to "q4".');
    }
    return {
        start: startDate,
        end: endDate,
    };
};
export const getYearToDateRange = (endDate) => {
    const currentYear = new Date().getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    if (endDate < startDate) {
        throw new Error('End date cannot be before the start of the current year.');
    }
    return {
        start: startDate,
        end: endDate,
    };
};
