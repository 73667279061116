/* eslint-disable */
import uiConfig from '@onereach/ui-config';
import config from '../env.json';
import externalProviders from '../env.external_providers.json';
import { isExactFeature, isLocal, SSO_TEST_FEATURE } from './utils/envCheckers';
// @ts-ignore
import runningProjects from 'runningProjects';
// @ts-ignore
import localOverrides from 'localOverrides';
/*
  If statement bellow is used for testing external providers inside AUTH UI module.
  Wiki: https://onereach.atlassian.net/wiki/spaces/AUTH/pages/266043393/Testing+env+setup+overview
*/
if (isExactFeature(SSO_TEST_FEATURE)) {
    const env = config['ENV'];
    const supportedEnvs = Object.keys(externalProviders);
    if (env && supportedEnvs.includes(env)) {
        config['PROVIDERS_ACCOUNT_ID'] = externalProviders[env].PROVIDERS_ACCOUNT_ID;
        if (!isLocal(config)) {
            config['EXTERNAL_PROVIDERS'] = externalProviders[env].EXTERNAL_PROVIDERS;
        }
        else {
            config['EXTERNAL_PROVIDERS'] = externalProviders[env].EXTERNAL_PROVIDERS.map(e => ({
                ...e,
                ...(e.name === 'oauth' ? { url: `${localOverrides.AUTH_UI_URL}/oauth_idp` } : null),
                redirectPath: `${localOverrides.AUTH_UI_URL}/activity`,
            }));
        }
    }
}
export default uiConfig(config, runningProjects, localOverrides);
