import { useResponsive, useTheme, extractCssVarName } from '@onereach/ui-components';
import { useCssVar } from '@vueuse/core';
import { computed, onMounted, onBeforeUnmount, defineComponent } from 'vue';
export default defineComponent({
    props: {
        background: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { isDesktop } = useResponsive();
        const htmlElement = document.documentElement;
        const theme = useTheme();
        const colorVariable = theme.backgroundColor['background-dark'];
        const backgroundColor = useCssVar(extractCssVarName(colorVariable));
        onMounted(() => {
            htmlElement.setAttribute('data-theme', 'dark');
            const themeColorMeta = document.querySelector('meta[name="theme-color"]');
            themeColorMeta === null || themeColorMeta === void 0 ? void 0 : themeColorMeta.setAttribute('content', backgroundColor.value);
        });
        onBeforeUnmount(() => {
            htmlElement.removeAttribute('data-theme');
        });
        const showBackground = computed(() => props.background && isDesktop);
        return {
            showBackground,
        };
    },
});
