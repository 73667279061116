import { Auth } from '@or-sdk/auth';
import config from '../config';
export default new Auth({
    cookieDomain: config.COOKIE_DOMAIN,
    cookiePrefix: config.COOKIE_NAME_PREFIX_NEW,
    allowGuestLogin: Boolean(config.GUEST_LOGIN),
    userCookieName: `${config.COOKIE_NAME_PREFIX}_name`,
    multiUserCookieName: `${config.COOKIE_NAME_PREFIX}_multi`,
    userExpireCookieName: `${config.COOKIE_NAME_PREFIX}_user_expire`,
    expireInShort: +`${config.COOKIE_EXPIRATION_SHORT}`,
    expireInLong: +`${config.COOKIE_EXPIRATION_LONG}`,
    sdkUrl: `${config.SDK_API_URL}`,
    authUiUrl: `${config.AUTH_UI_URL}`,
    allowIframe: Boolean(config.ALLOW_IFRAME),
});
