import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "layout-column typography-body-2-regular" };
const _hoisted_2 = { class: "type list-disc pl-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('multi_user.set_password.The password must')) + " ", 1),
        _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.passwordPoliciesExtended, (policy) => {
                return (_openBlock(), _createElementBlock("li", {
                    key: policy.name,
                    class: _normalizeClass(policy.classes)
                }, _toDisplayString(policy.name.toLowerCase()), 3));
            }), 128))
        ])
    ]));
}
