import { defineComponent } from 'vue';
import OrLogo from './OrLogo.svg';
export default defineComponent({
    name: 'OrLogo',
    setup() {
        return {
            logo: OrLogo,
        };
    },
});
