import axios from 'axios';
import config from '../config';
const gatewayUrl = config.GATEWAY_URL;
const providersAccountId = config.PROVIDERS_ACCOUNT_ID;
export const authenticateExternalProvider = async (providerUrl, accessToken, redirectUri, { userId, accountId }) => {
    const params = {
        token: accessToken,
        redirect_uri: redirectUri,
        user_id: userId,
        account_id: accountId,
    };
    // eslint-disable-next-line max-len
    const providerPath = (/^https?:\/\//).test(providerUrl) ? providerUrl : `${gatewayUrl}/${providersAccountId}/${providerUrl}`;
    return await axios.get(providerPath, {
        params,
    }).then(response => response.data);
};
