import * as Sentry from '@sentry/browser';
import _, { noop } from 'lodash';
export default function SentryMonitoringPlugin(config) {
    const { disabled = false, ignore = [], } = config;
    if (disabled) {
        return noop;
    }
    return ({ store }) => {
        store.$onAction(({ name, args, after, onError }) => {
            // logic for ignoring
            for (let i = 0; i <= ignore.length; i++) {
                const item = ignore[i];
                if (_.isString(item) && store.$id === item) {
                    return;
                }
                if (_.isObject(item)) {
                    for (const [storeId, actions] of Object.entries(item)) {
                        if (storeId === store.$id && actions.includes(name)) {
                            return;
                        }
                    }
                }
            }
            Sentry.startSpanManual({
                op: 'Store actions monitoring',
                name: `${store.$id}@${name}`,
                attributes: {
                    args: JSON.stringify(args),
                },
            }, (span) => {
                after(() => span.end());
                onError(() => span.end());
            });
        });
    };
}
