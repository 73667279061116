import { ErrorActions } from '../types/errors';
export const getFormatedError = (value, translate) => {
    const message = typeof value === 'object' && value !== null ? value.message || '' : '';
    const result = {
        message: 'Error. Please try again.',
    };
    if (value === 'Unauthorized') {
        result.message = '';
        result.action = ErrorActions.Expired;
    }
    else if (value === 'Invalid verification code') {
        result.message = 'Invalid verification code';
    }
    else if (!navigator.onLine) {
        result.message = 'Network Error. Please check your connection and try again.';
    }
    else if (['Invalid username and/or password', 'Wrong credentials'].includes(message)) {
        result.message = translate('login.login.The email and password combination is not valid');
    }
    else if (message === 'Access suspended') {
        result.message = 'Account access is suspended. Please contact OneReach.ai support.';
    }
    else if (message === 'Two Factor token invalid') {
        result.message = 'Verification code is invalid or expired. Please try again.';
    }
    else if (message.includes('account is missing or disabled') || message.includes('Account is disabled')) {
        result.message = translate('multi_user.list_users.accountDisabled');
    }
    else if (message.includes('Incorrect Captcha: low score')) {
        result.message = '';
        result.action = ErrorActions.RenderCaptcha;
    }
    else if (message.includes('Incorrect Captcha')) {
        result.message = 'Error verifying reCAPTCHA. Please try again.';
    }
    else if (message.includes('You are allowed')) {
        result.message = message;
    }
    else if (message === 'You have exceeded the request limit') {
        result.message = translate('multi_user.list_users.Too many requests');
    }
    return result;
};
